/**
 * @description Create
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Grid, Item, Label, Header, Segment } from 'semantic-ui-react';
import CommitteeChair from '../../Committees/CommitteeChair';
import { flattenToAppURL } from '@plone/volto/helpers';
import './committee-list.css';

const CommitteeListTemplate = ({ items }) => {
  // Debug log to see what data we're getting
  console.log('CommitteeListTemplate items:', items.map(item => ({
    title: item.title,
    chair_name: item.committee_chair_name,
    chair: item.committee_chair,
    metadata: item.metadata,
    '@id': item['@id'],
    fullobjects: item.fullobjects,
  })));

  // Separate featured and regular committees
  const featuredCommittees = items.filter(item => 
    item.subjects?.includes('Featured Committee')
  );
  const regularCommittees = items.filter(item => 
    !item.subjects?.includes('Featured Committee')
  );

  // Custom style for header with minimal bottom margin
  const headerStyle = {
    marginBottom: '0.2rem',
    marginTop: '0'
  };

  const renderCommitteeList = (committees) => {
    return committees.map((item, index) => (
      <Grid.Column key={`committee-${index}`}>
        <a href={item['@id']} style={{ textDecoration: 'none' }}>
          <Item.Group className="committee-item">
            <Item className="committee-overview-wrap">
              <Item.Content>
                <Item.Header>
                  {item.title}
                </Item.Header>
                <Item.Description>
                  {item.description}
                  {item.subjects?.length > 0 && (
                    <Label.Group>
                      {item.subjects.map((tag, index) => (
                        <Label key={index}>{tag}</Label>
                      ))}
                    </Label.Group>
                  )}
                </Item.Description>
              </Item.Content>
            </Item>

            <div className="committee-chairs">
              <h2 className="committee-chairs-heading">
                Committee Leadership
              </h2>
              {/* Show chair if we have any chair information */}
              {(item.committee_chair_name || item.committee_chair || item.committee_chair_title || item.committee_chair_firm) && (
                <CommitteeChair
                  chairPosition="chair"
                  chairTitle={item.committee_chair_title}
                  chairName={item.committee_chair_name || item.committee_chair || item.chair}
                  chair={item.committee_chair || item.chair}
                  contactButton={
                    item.hasChairVcard
                      ? flattenToAppURL(`${item.getURL}/@@download/committee_chair_vcard`)
                      : null
                  }
                  chairPhoto={
                    item.image_scales?.committee_chair_photo?.[0]?.scales
                      ?.tile.download
                      ? flattenToAppURL(`${item.getURL}/${item.image_scales.committee_chair_photo[0].scales.tile.download}`)
                      : undefined
                  }
                  chairFirm={item.committee_chair_firm}
                  chairLocation={item.chair_location}
                  chairTitleOverride={item.chair_title_override}
                  viceChairTitleOverride={item.vice_chair_title_override}
                  secondViceChairTitleOverride={item.second_vice_chair_title_override}
                />
              )}
              {/* Show vice chair if we have any vice chair information */}
              {(item.committee_vice_chair_name || item.committee_vice_chair || item.committee_vice_chair_title || item.committee_vice_chair_firm) && (
                <CommitteeChair
                  chairPosition="co-chair"
                  contactButton={
                    item.hasViceChairVcard
                      ? flattenToAppURL(`${item.getURL}/@@download/committee_vice_chair_vcard`)
                      : null
                  }
                  chairPhoto={
                    item.image_scales?.committee_vice_chair_photo?.[0]
                      ?.scales?.tile.download
                      ? flattenToAppURL(`${item.getURL}/${item.image_scales.committee_vice_chair_photo[0].scales.tile.download}`)
                      : undefined
                  }
                  chairTitle={item.committee_vice_chair_title}
                  chairName={item.committee_vice_chair_name}
                  chair={item.committee_vice_chair}
                  chairFirm={item.committee_vice_chair_firm}
                  chairLocation={item.vice_chair_location}
                  chairTitleOverride={item.chair_title_override}
                  viceChairTitleOverride={item.vice_chair_title_override}
                  secondViceChairTitleOverride={item.second_vice_chair_title_override}
                />
              )}
              {/* Show second vice chair if we have any second vice chair information */}
              {(item.committee_second_vice_chair_name || item.committee_second_vice_chair || item.committee_second_vice_chair_title || item.committee_second_vice_chair_firm) && (
                <CommitteeChair
                  chairPosition="second-vice-chair"
                  contactButton={
                    item.committee_second_vice_chair_vcard
                      ? flattenToAppURL(`${item.getURL}/@@download/committee_second_vice_chair_vcard`)
                      : null
                  }
                  chairPhoto={
                    item.image_scales?.committee_second_vice_chair_photo?.[0]
                      ?.scales?.tile.download
                      ? flattenToAppURL(`${item.getURL}/${item.image_scales.committee_second_vice_chair_photo[0].scales.tile.download}`)
                      : undefined
                  }
                  chairTitle={item.committee_second_vice_chair_title}
                  chairName={item.committee_second_vice_chair_name}
                  chair={item.committee_second_vice_chair}
                  chairFirm={item.committee_second_vice_chair_firm}
                  chairLocation={item.committee_second_vice_chair_location}
                  chairTitleOverride={item.chair_title_override}
                  viceChairTitleOverride={item.vice_chair_title_override}
                  secondViceChairTitleOverride={item.second_vice_chair_title_override}
                />
              )}
            </div>
          </Item.Group>
        </a>
      </Grid.Column>
    ));
  };

  return (
    <div className="committee-list-wrapper">
      {/* Featured Committees Section */}
      {featuredCommittees.length > 0 && (
        <Segment basic>
          <Header as="h2" style={headerStyle}>Featured Committees</Header>
          <Grid columns={2} stackable>
            {renderCommitteeList(featuredCommittees)}
          </Grid>
        </Segment>
      )}

      {/* Regular Committees Section */}
      {regularCommittees.length > 0 && (
        <Segment basic>
          <Grid columns={2} stackable>
            {renderCommitteeList(regularCommittees)}
          </Grid>
        </Segment>
      )}
    </div>
  );
};

CommitteeListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CommitteeListTemplate;
