import React, { useEffect, useState } from 'react';
import CommitteeChair from './CommitteeChair';
import './committee.css';
import { Input, Icon, Grid, Item, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';

const Committees = (props) => {
  const dispatch = useDispatch();
  const committees = useSelector((state) => state.search.items);
  const [term, setTerm] = useState('');
  const [dropDownFilter, setDropDownFilter] = useState({ value: '' });
  const [timestamp, setTimestamp] = useState(Date.now());

  // Function to force refresh
  const refreshData = () => {
    setTimestamp(Date.now());
  };

  useEffect(() => {
    console.log('Fetching committees...');
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['committee'],
          fullobjects: true,
          review_state: 'open',
          sort_on: 'getObjPositionInParent',
          metadata_fields: [
            'committee_chair',
            'committee_chair_name',
            'committee_chair_title',
            'committee_chair_firm',
            'committee_vice_chair',
            'committee_vice_chair_name',
            'committee_vice_chair_title',
            'committee_vice_chair_firm',
            'committee_second_vice_chair',
            'committee_second_vice_chair_name',
            'committee_second_vice_chair_title',
            'committee_second_vice_chair_firm',
            'chair_title_override',
            'vice_chair_title_override',
            'second_vice_chair_title_override'
          ],
          _ts: timestamp, // Add timestamp to prevent caching
        },
        term,
      ),
    );
  }, [dispatch, term, timestamp]);

  // Add effect to refresh data when component mounts or becomes visible
  useEffect(() => {
    refreshData();
    
    // Add visibility change listener
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshData();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (committees) {
      committees.forEach(committee => {
        console.log('Committee:', committee.title);
        console.log('Chair data:', {
          chair_name: committee.committee_chair_name,
          chair: committee.committee_chair,
          chair_title: committee.committee_chair_title,
          chair_firm: committee.committee_chair_firm,
          all_chair_fields: Object.keys(committee).filter(key => key.includes('chair'))
        });
      });
    }
  }, [committees]);

  const updateSearchResults = (e) => {
    setTerm(e.target.value);
  };

  const getTags = (committees) => {
    return [
      ...new Set(
        [].concat.apply([], committees.map((item) => item.subjects)),
      ),
    ].map((itx, index) => ({ key: index, value: itx, text: itx }));
  };

  return (
    <div>
      <Input fluid icon placeholder="Search Committees">
        <Icon name="search" />
        <input
          type="text"
          className="prompt"
          value={term}
          onChange={updateSearchResults}
        />
      </Input>
      <Grid className="committee-list">
        <Grid.Row className="committee-list-grid">
          {committees &&
            committees.map((item, index) => (
              <Grid.Column key={index} className="committee-column">
                <Item.Group className="committee-item" relaxed>
                  <Item className="committee-overview-wrap">
                    <Item.Content verticalAlign="middle">
                      <Item.Header as="a" href={item['@id']}>
                        {item.title}
                      </Item.Header>
                      <Item.Description>
                        <Label.Group>
                          {/*
                          {item.subjects.map((tag, index) => (
                            <Label key={index}>{tag}</Label>
                          ))}{' '}
                          */}
                        </Label.Group>
                        {item.description}
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <div>
                    <div className="committee-wrap">
                      <a
                        className="button committee-readmore-button"
                        href={item['@id']}
                      >
                        Read more
                      </a>
                    </div>
                    <div className="committee-chairs">
                      <h2 className="committee-chairs-heading">
                        Committee Leadership
                      </h2>
                      {(item.committee_chair_name || item.committee_chair || item.committee_chair_title || item.committee_chair_firm) && (
                        <CommitteeChair
                          chairPosition="chair"
                          chairPhoto={
                            item.committee_chair_photo?.scales.thumb.download
                          }
                          contactButton={item?.committee_chair_vcard?.download}
                          chairTitle={item.committee_chair_title}
                          chairName={item.committee_chair_name}
                          chair={item.committee_chair}
                          chairFirm={item.committee_chair_firm}
                          chairLocation={item.committee_chair_location}
                          chairTitleOverride={item.chair_title_override}
                          viceChairTitleOverride={item.vice_chair_title_override}
                          secondViceChairTitleOverride={item.second_vice_chair_title_override}
                        />
                      )}
                      {(item.committee_vice_chair_name || item.committee_vice_chair || item.committee_vice_chair_title || item.committee_vice_chair_firm) && (
                        <CommitteeChair
                          chairPosition="co-chair"
                          chairPhoto={
                            item.committee_vice_chair_photo?.scales.thumb.download
                          }
                          contactButton={
                            item?.committee_vice_chair_vcard?.download
                          }
                          chairTitle={item.committee_vice_chair_title}
                          chairName={item.committee_vice_chair_name}
                          chair={item.committee_vice_chair}
                          chairFirm={item.committee_vice_chair_firm}
                          chairLocation={item.committee_vice_chair_location}
                          chairTitleOverride={item.chair_title_override}
                          viceChairTitleOverride={item.vice_chair_title_override}
                          secondViceChairTitleOverride={item.second_vice_chair_title_override}
                        />
                      )}
                      {(item.committee_second_vice_chair_name || item.committee_second_vice_chair || item.committee_second_vice_chair_title || item.committee_second_vice_chair_firm) && (
                        <CommitteeChair
                          chairPosition="second-vice-chair"
                          chairPhoto={
                            item.committee_second_vice_chair_photo?.scales.thumb.download
                          }
                          contactButton={
                            item?.committee_second_vice_chair_vcard?.download
                          }
                          chairTitle={item.committee_second_vice_chair_title}
                          chairName={item.committee_second_vice_chair_name}
                          chair={item.committee_second_vice_chair}
                          chairFirm={item.committee_second_vice_chair_firm}
                          chairLocation={item.committee_second_vice_chair_location}
                          chairTitleOverride={item.chair_title_override}
                          viceChairTitleOverride={item.vice_chair_title_override}
                          secondViceChairTitleOverride={item.second_vice_chair_title_override}
                        />
                      )}
                    </div>
                  </div>
                </Item.Group>
              </Grid.Column>
            ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Committees;
