// schema.js
export const schemaGumletVideo = (props) => {
  return {
    required: ['playbackURL'],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'playbackURL', 'cleCode', 'description'],
      },
      {
        id: 'options',
        title: 'Player Options',
        fields: ['autoplay', 'controls'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      playbackURL: {
        title: 'Playback URL',
        widget: 'text',
        description: 'Enter the Gumlet HLS Playback URL',
      },
      cleCode: {
        title: 'CLE Credit Code',
        widget: 'text',
        description:
          'This code will be revealed when the video is fully watched',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      autoplay: {
        title: 'Autoplay',
        type: 'boolean',
        default: false,
        description:
          'Start playing automatically (not recommended for accessibility)',
      },
      controls: {
        title: 'Show Controls',
        type: 'boolean',
        default: true,
        description: 'Show video player controls',
      },
    },
  };
};

export default schemaGumletVideo;
