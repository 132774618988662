import React, { useState } from 'react';
import { Icon } from '@plone/volto/components';
import { When } from '@plone/volto/components/theme/View/EventDatesInfo';
import { Card, Label, Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import downSVG from '@plone/volto/icons/down-key.svg';
import worldSVG from '@plone/volto/icons/world.svg';
import userSVG from '@plone/volto/icons/user.svg';
import checkSVG from '@plone/volto/icons/check.svg';
import './legislation-list.less';

const LegislationListTemplate = ({ items }) => {
  const [expandedItems, setExpandedItems] = useState({});

  // Debug log to see what data we're receiving
  console.log('Legislation items:', items);

  const toggleHistory = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedItems({
      ...expandedItems,
      [id]: !expandedItems[id],
    });
  };

  const handleExternalClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'blue';
      case 'enacted':
        return 'green';
      case 'dead':
        return 'red';
      default:
        return 'grey';
    }
  };

  return (
    <div className="legislation-list-template">
      {items.map((item) => {
        // Debug log for each item
        console.log('Processing item:', item);
        
        return (
          <UniversalLink
            key={item['@id']}
            href={item['@id']}
            className="legislation-card-link"
          >
            <Card fluid className="legislation-card">
              <Card.Content>
                <div className="legislation-header">
                  <div className="header-main">
                    {item.legislative_session && (
                      <div className="session-info">
                        {item.legislative_session}
                      </div>
                    )}
                    <div className="header-actions">
                      {item.external_url && (
                        <Button
                          icon
                          basic
                          className="external-link-button"
                          onClick={(e) => handleExternalClick(e, item.external_url)}
                          title="View on Texas Legislature Online"
                        >
                          <Icon name={worldSVG} size="24px" />
                        </Button>
                      )}
                    </div>
                  </div>
                  {item.review_state && (
                    <Label color={getStatusColor(item.review_state)} className="status-label">
                      {item.review_state}
                    </Label>
                  )}
                </div>

                <Card.Header>
                  {console.log('Full item data:', JSON.stringify(item, null, 2))}
                  {console.log('Title:', item.Title)}
                  {console.log('bill_number:', item.bill_number)}
                  {console.log('billnumber:', item.billnumber)}
                  {console.log('BillNumber:', item.BillNumber)}
                  {(item.bill_number || item.getId || item.id) && (
                    <div className="bill-number-container">
                      <span className="bill-label">Bill Number</span>
                      <h2 className="bill-number">
                        {(item.bill_number || item.getId || item.id || '').toUpperCase()}
                      </h2>
                    </div>
                  )}
                  {item.Title && (
                    <h3 className="bill-title">{item.Title}</h3>
                  )}
                </Card.Header>

                <Card.Description>
                  {item.Description && (
                    <p className="bill-description">{item.Description}</p>
                  )}
                  
                  {(item.primary_sponsor || (item.co_sponsors && item.co_sponsors.length > 0)) && (
                    <div className="sponsors-section">
                      {item.primary_sponsor && (
                        <div className="sponsor-item">
                          <Icon name={userSVG} size="24px" />
                          <div className="sponsor-info">
                            <div className="sponsor-label">Primary Sponsor</div>
                            <div className="sponsor-name">{item.primary_sponsor}</div>
                          </div>
                        </div>
                      )}
                      {item.co_sponsors?.length > 0 && (
                        <div className="sponsor-item">
                          <Icon name={userSVG} size="24px" />
                          <div className="sponsor-info">
                            <div className="sponsor-label">Co-Sponsors</div>
                            <div className="sponsor-name">{item.co_sponsors.join(', ')}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {item.status_history?.length > 0 && (
                    <div className="history-section">
                      <Button 
                        basic 
                        onClick={(e) => toggleHistory(item['@id'], e)}
                        className="history-toggle"
                      >
                        <Icon
                          name={downSVG}
                          className={cx('history-icon', {
                            'is-expanded': expandedItems[item['@id']],
                          })}
                        />
                        View Full Bill History
                      </Button>
                      
                      {expandedItems[item['@id']] && (
                        <div className="history-timeline">
                          {item.status_history.map((entry, index) => (
                            <div key={index} className="history-entry">
                              <div className="history-marker">
                                <Icon name={checkSVG} className="check-icon" size="20px" />
                              </div>
                              <div className="history-content">
                                <div className="history-date">
                                  <When datetime={entry.date} />
                                </div>
                                <div className="history-status">
                                  <Label color={getStatusColor(entry.status)}>
                                    {entry.status}
                                  </Label>
                                </div>
                                {entry.details && (
                                  <div className="history-details">
                                    {entry.details}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          </UniversalLink>
        );
      })}
    </div>
  );
};

export default LegislationListTemplate; 