import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, List, Button } from 'semantic-ui-react';
import { FaFilePdf } from 'react-icons/fa';
import moment from 'moment';
import { getUserName } from '../../../../actions/userfullname';
import './gridlist.css';

const GridListTemplate = ({ items }) => {
  const dispatch = useDispatch();
  const userNames = useSelector((state) => state.userfullname.userNames);

  useEffect(() => {
    const allCreators = [
      ...new Set(items.flatMap((item) => item.listCreators || [])),
    ];

    console.log('All creators:', allCreators);
    console.log('Current userNames state:', userNames);

    allCreators.forEach((userId) => {
      if (!userNames[userId] && userId) {
        console.log(`Dispatching getUserName for userId: ${userId}`);
        dispatch(getUserName(userId));
      }
    });
  }, [items, userNames, dispatch]);

  const handleCardClick = (e, url) => {
    // Prevent click if the click was on a button or PDF link
    if (e.target.closest('.grid-list-footer')) {
      e.preventDefault();
      return;
    }
    window.location.href = url;
  };

  return (
    <Grid columns={2}>
      {items.map((item, index) => {
        const publicationDate = item.Date
          ? moment(item.Date).format('MMMM D, YYYY')
          : 'No publication date';
        const documentType = item.document_type || '';
        const folderName =
          item.parentTitle ||
          (item['@id']
            ? item['@id'].split('/').slice(-2, -1)[0]
            : 'Unknown folder');
        const contributors = item.listCreators || [];

        return (
          <Grid.Column key={`item-index-${index}`}>
            <div 
              onClick={(e) => handleCardClick(e, item.getURL)}
              className="clickable-card"
              role="link"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleCardClick(e, item.getURL);
                }
              }}
            >
              <Card fluid>
                <Card.Content className="grid-list-content">
                  <Card.Header>
                    {item.review_state === 'internally_published' && (
                      <div className="grid-list-sash">Members Only</div>
                    )}
                    <h4 className="grid-label">{documentType}</h4>
                    <h3 className="grid-list-location">{folderName}</h3>
                    <span className="grid-list-heading">
                      {item.title}
                    </span>
                  </Card.Header>
                  <Card.Meta>
                    by
                    <span className="list-creators">
                      {' '}
                      {contributors
                        .map((userId) => userNames[userId] || userId)
                        .join(', ')}
                    </span>
                    {publicationDate}
                  </Card.Meta>
                  <Card.Description className="grid-list-description">
                    {item.description}
                  </Card.Description>
                  {item.hasFile && (
                    <Card.Content extra className="grid-list-footer">
                      <a 
                        href={`${item.getURL}/@@display-file/file`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FaFilePdf
                          className="grid-list-icon"
                          style={{ marginRight: '5px' }}
                        />
                      </a>
                      {item.countChildren > 0 && (
                        <>
                          <span> &</span>
                          <Button
                            as="a"
                            href={item.getURL}
                            size="small"
                            className="grid-list-button"
                            style={{ marginLeft: '10px' }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Supporting Materials ({item.countChildren})
                          </Button>
                        </>
                      )}
                    </Card.Content>
                  )}
                </Card.Content>
              </Card>
            </div>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

GridListTemplate.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Date: PropTypes.string,
      document_type: PropTypes.string,
      Type: PropTypes.string,
      '@type': PropTypes.string,
      parentTitle: PropTypes.string,
      '@id': PropTypes.string,
      title: PropTypes.string,
      getURL: PropTypes.string,
      listCreators: PropTypes.arrayOf(PropTypes.string),
      review_state: PropTypes.string,
      description: PropTypes.string,
      hasFile: PropTypes.bool,
      countChildren: PropTypes.number,
    }),
  ).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default GridListTemplate;
