import React, { useState, useEffect, useRef } from 'react';
import { Container, Message, Button } from 'semantic-ui-react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './styles.css';

const GumletVideoView = (props) => {
  const { data, block } = props;
  const [error, setError] = useState(null);
  const playerRef = useRef(null);
  const [completion, setCompletion] = useState(0);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [showCLECode, setShowCLECode] = useState(false);
  const playerInstance = useRef(null);

  // Create unique ID for this video instance
  const videoId = block || Math.random().toString(36).substring(7);
  const storageKeyPrefix = `video-${videoId}-${data.playbackURL}`;

  useEffect(() => {
    // Check if this specific video was previously completed
    if (data.playbackURL) {
      const completed = localStorage.getItem(`${storageKeyPrefix}-completed`);
      if (completed === 'true') {
        setHasCompleted(true);
      }
      const savedProgress = localStorage.getItem(
        `${storageKeyPrefix}-progress`,
      );
      if (savedProgress) {
        setCompletion(parseInt(savedProgress, 10));
      }
    }
  }, [data.playbackURL, storageKeyPrefix]);

  useEffect(() => {
    const fetchVideo = async () => {
      if (!data.playbackURL) return;
      try {
        const response = await fetch(
          `/++api++/@gumlet-video?playback_url=${encodeURIComponent(data.playbackURL)}`,
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch video: ${response.statusText}`);
        }
        const result = await response.json();
        initializePlayer(result.url);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching video:', err);
      }
    };
    fetchVideo();

    return () => {
      if (playerInstance.current) {
        playerInstance.current.dispose();
        playerInstance.current = null;
      }
    };
  }, [data.playbackURL]);

  const initializePlayer = (url) => {
    if (playerRef.current) {
      const player = videojs(playerRef.current, {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: url,
            type: 'application/x-mpegURL',
          },
        ],
        html5: {
          hls: {
            overrideNative: true,
          },
        },
        playerId: videoId,
      });

      playerInstance.current = player;

      // Restore progress for this specific video if exists
      const savedTime = localStorage.getItem(`${storageKeyPrefix}-time`);
      if (savedTime) {
        player.currentTime(parseFloat(savedTime));
      }

      // Track progress for this specific video
      player.on('timeupdate', () => {
        const currentTime = player.currentTime();
        const duration = player.duration();
        if (duration > 0) {
          const percentComplete = (currentTime / duration) * 100;
          setCompletion(Math.round(percentComplete));

          localStorage.setItem(
            `${storageKeyPrefix}-progress`,
            Math.round(percentComplete),
          );
          localStorage.setItem(`${storageKeyPrefix}-time`, currentTime);

          // Mark as completed at 95%
          if (percentComplete >= 95 && !hasCompleted) {
            setHasCompleted(true);
            localStorage.setItem(`${storageKeyPrefix}-completed`, 'true');
            localStorage.setItem(
              `${storageKeyPrefix}-completed-date`,
              new Date().toISOString(),
            );
          }
        }
      });

      player.on('ready', () => {
        console.log(`Video player ${videoId} is ready!`);
      });

      player.on('error', (error) => {
        console.error(`Video player ${videoId} error:`, error);
        setError('Error playing video');
      });
    }
  };

  const generateShareableLink = () => {
    const shareableData = {
      playbackURL: data.playbackURL,
      completion,
      hasCompleted,
    };
    const encodedData = encodeURIComponent(JSON.stringify(shareableData));
    return `${window.location.href.split('?')[0]}/share?data=${encodedData}`;
  };

  const handleShareLink = () => {
    const link = generateShareableLink();
    navigator.clipboard.writeText(link).then(() => {
      alert('Shareable link copied to clipboard!');
    });
  };

  return (
    <Container className="gumlet-video-container">
      {data.title && <h2>{data.title}</h2>}
      {data.description && <p>{data.description}</p>}
      {error && <div className="error">Error loading video: {error}</div>}
      <div data-vjs-player>
        <video
          ref={playerRef}
          className="video-js vjs-default-skin"
          id={`video-player-${videoId}`}
        />
      </div>
      {!error && (
        <div className="video-stats">
          <div className="video-progress">
            <div className="progress-bar" style={{ width: `${completion}%` }} />
          </div>
          <div className="progress-stats">
            {completion}% watched
            {hasCompleted && (
              <span className="completion-badge">✓ Completed</span>
            )}
          </div>
        </div>
      )}

      {hasCompleted && data.cleCode && (
        <div className="cle-code-section">
          <Message positive>
            <Message.Header>
              Video Completed - CLE Credit Code Available
            </Message.Header>
            {showCLECode ? (
              <div className="cle-code-display">
                <p>Your CLE Credit Code:</p>
                <div className="cle-code">{data.cleCode}</div>
              </div>
            ) : (
              <Button
                primary
                onClick={() => setShowCLECode(true)}
                className="show-code-button"
              >
                Show CLE Credit Code
              </Button>
            )}
          </Message>
        </div>
      )}
      
      {/* Share Progress Button */}
      <Button onClick={handleShareLink} className="share-link-button">
        Share Progress
      </Button>
    </Container>
  );
};

export default GumletVideoView;
