import { Container, Grid, Card, Image,  
  Item,
  ItemMeta,
  ItemImage,
  ItemHeader,
  ItemGroup,
  ItemExtra,
  ItemDescription,
  ItemContent } from 'semantic-ui-react';
import Moment from 'moment';
import { UniversalLink, Icon, Component } from '@plone/volto/components';
import ArrowRightSVG from '@plone/volto/icons/ahead.svg';
import "./component.css";

const ListingGridVariation = (props) => {
  const { items = [] } = props;

  // Debug logging
  console.log('ResourceGridTemplate items:', items);

  // Helper function to safely get text content
  const getTextContent = (value) => {
    if (!value) return '';
    if (typeof value === 'string') return value;
    if (typeof value === 'object' && value.title) return value.title;
    if (typeof value === 'object' && value.token) return value.token;
    return '';
  };

  // If no items, show a message or return null
  if (!items || items.length === 0) {
    return (
      <div className="listing-grid">
        <Container>
          <p>No items found.</p>
        </Container>
      </div>
    );
  }

  return (
    <div className="listing-grid">
      <Container>
        <Grid stackable columns={3}>
          <Grid.Row>
            {items.map((item, index) => {
              // Debug logging for each item
              console.log('Processing item:', item);
              
              if (!item) return null;

              return (
                <Grid.Column key={item['@id'] || index}>
                  <ItemGroup>
                    <Item as='a' href={item['@id']}>
                      {item.image_scales && (
                        <Component 
                          className="preview-image" 
                          componentName="PreviewImage" 
                          item={item} 
                          alt={getTextContent(item.title)} 
                        />
                      )}
                      <ItemContent>
                        <ItemMeta>
                          <div className="item-title">
                            {getTextContent(item.title)}
                          </div>
                        </ItemMeta>
                        {item.description && (
                          <ItemDescription>
                            {getTextContent(item.description)}
                          </ItemDescription>
                        )}
                        {item.subjects && item.subjects.length > 0 && (
                          <ItemExtra>
                            {item.subjects.map((subject, idx) => (
                              <span key={idx} className="subject-tag">
                                {getTextContent(subject)}
                              </span>
                            ))}
                          </ItemExtra>
                        )}
                      </ItemContent>
                    </Item>
                  </ItemGroup>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default ListingGridVariation;