import LegislativeBillTrackerView from './LegislativeBillTrackerView';
import LegislativeBillTrackerEdit from './LegislativeBillTrackerEdit';
import listIcon from '@plone/volto/icons/list-bullet.svg';

export default (config) => {
  config.blocks.blocksConfig.legislativeBillTracker = {
    id: 'legislativeBillTracker',
    title: 'Legislative Bill Tracker',
    icon: listIcon,
    group: 'common',
    view: LegislativeBillTrackerView,
    edit: LegislativeBillTrackerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
    variations: [],
  };
  
  return config;
};

export { LegislativeBillTrackerView, LegislativeBillTrackerEdit }; 