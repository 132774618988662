import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Container, Grid, Icon } from 'semantic-ui-react';
import { getUser } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import './Author.less';

const Author = () => {
  const dispatch = useDispatch();
  const { author_id } = useParams();
  const user = useSelector((state) => state.users?.user || {});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (author_id) {
      dispatch(getUser(author_id));
    }
    setLoading(false);
  }, [dispatch, author_id]);

  const userNotFound = !user || Object.keys(user).length === 0;

  if (loading) {
    return (
      <Container>
        <div className="loading">
          <Icon name="spinner" loading />
          Loading profile...
        </div>
      </Container>
    );
  }

  if (userNotFound) {
    return (
      <Container>
        <div className="profile-not-found">
          <Icon name="user outline" size="huge" />
          <h2>Profile not found</h2>
        </div>
      </Container>
    );
  }

  const {
    fullname,
    portrait,
    location: userLocation,
    practice_areas = [],
    email,
    phone,
    website,
    text,
    id,
  } = user;

  return (
    <Container>
      <div className="author-view">
        <div className="profile-header">
          <div className="profile-image-container">
            {portrait ? (
              <img
                className="portrait-image"
                src={`${config.settings.apiPath}/++api++/@portrait/${id}`}
                alt={fullname}
              />
            ) : (
              <div className="no-portrait">
                <Icon name="user outline" />
              </div>
            )}
          </div>

          <div className="profile-info">
            <h1 className="name">{fullname}</h1>
            {userLocation && (
              <div className="location">
                <Icon name="map marker alternate" />
                {userLocation}
              </div>
            )}
            <div className="action-buttons">
              {email && (
                <a href={`mailto:${email}`} className="ui button primary">
                  <Icon name="mail" />
                  Contact Attorney
                </a>
              )}
              <Link 
                to="/member-search" 
                className="ui button secondary"
              >
                <Icon name="search" />
                Member Directory
              </Link>
            </div>
          </div>
        </div>

        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <div className="section">
                <h2>Practice Areas</h2>
                <div className="practice-areas">
                  {practice_areas.map((area, index) => (
                    <div key={index} className="area">
                      {area}
                    </div>
                  ))}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={10}>
              <div className="section">
                <h2>About the Practice</h2>
                <div className="about-practice">
                  {text && (
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                  )}
                </div>
              </div>
              
              <div className="section">
                <h2>Contact Information</h2>
                <div className="contact-info">
                  {email && (
                    <div className="contact-row">
                      <div className="label">Email:</div>
                      <div><a href={`mailto:${email}`}>{email}</a></div>
                    </div>
                  )}
                  {phone && (
                    <div className="contact-row">
                      <div className="label">Phone:</div>
                      <div>{phone}</div>
                    </div>
                  )}
                  {website && (
                    <div className="contact-row">
                      <div className="label">Website:</div>
                      <div>
                        <a href={website} target="_blank" rel="noopener noreferrer">
                          {website}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  );
};

export default Author;
