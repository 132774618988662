import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Form, Grid, Accordion, Icon, Message, Header, Divider } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  leadershipBlock: {
    id: 'Committee Leadership Block',
    defaultMessage: 'Committee Leadership Block',
  },
  leadershipDescription: {
    id: 'leadershipDescription',
    defaultMessage: 'This block automatically displays committee leadership information from the current committee.',
  },
  note: {
    id: 'Note',
    defaultMessage: 'How to Edit Leadership',
  },
  automaticDataNote: {
    id: 'automaticDataNote',
    defaultMessage: 'Leadership information is pulled directly from the committee content. To modify the leadership displayed, you need to edit the committee content itself.',
  },
  editCommittee: {
    id: 'editCommittee',
    defaultMessage: 'Important Note:',
  },
  editCommitteeDescription: {
    id: 'editCommitteeDescription',
    defaultMessage: 'This is a display-only block that cannot be configured. The block automatically shows the leadership information (Chair, Vice Chair, etc.) from the committee content type.',
  },
  fieldInfo: {
    id: 'fieldInfo',
    defaultMessage: 'Available Fields',
  },
  fieldInfoDescription: {
    id: 'fieldInfoDescription',
    defaultMessage: 'The following fields from the committee will be displayed if they exist:',
  },
  chairFields: {
    id: 'chairFields',
    defaultMessage: 'Chair: Name, Title, Firm, Photo',
  },
  viceChairFields: {
    id: 'viceChairFields',
    defaultMessage: 'Vice Chair: Name, Title, Firm, Photo',
  },
  secondViceChairFields: {
    id: 'secondViceChairFields',
    defaultMessage: 'Second Vice Chair: Name, Title, Firm, Photo',
  },
});

const CommitteeLeadershipEdit = ({ data, block, onChangeBlock, intl }) => {
  // Initialize with the first accordion open by default
  const [activeAccordions, setActiveAccordions] = useState([0]);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeAccordions.includes(index) 
      ? activeAccordions.filter(item => item !== index) 
      : [...activeAccordions, index];
    
    setActiveAccordions(newIndex);
  };

  return (
    <Segment className="form sidebar-image-data">
      <Header as="h2">{intl.formatMessage(messages.leadershipBlock)}</Header>
      <p>{intl.formatMessage(messages.leadershipDescription)}</p>
      
      <Divider />
      
      <Message info>
        <Message.Header>{intl.formatMessage(messages.editCommittee)}</Message.Header>
        <p>{intl.formatMessage(messages.editCommitteeDescription)}</p>
      </Message>
      
      <Form>
        <Accordion fluid styled className="form">
          <Accordion.Title
            active={activeAccordions.includes(0)}
            index={0}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            {intl.formatMessage(messages.note)}
          </Accordion.Title>
          <Accordion.Content active={activeAccordions.includes(0)}>
            <p>{intl.formatMessage(messages.automaticDataNote)}</p>
          </Accordion.Content>
          
          <Accordion.Title
            active={activeAccordions.includes(1)}
            index={1}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            {intl.formatMessage(messages.fieldInfo)}
          </Accordion.Title>
          <Accordion.Content active={activeAccordions.includes(1)}>
            <p>{intl.formatMessage(messages.fieldInfoDescription)}</p>
            <ul>
              <li>{intl.formatMessage(messages.chairFields)}</li>
              <li>{intl.formatMessage(messages.viceChairFields)}</li>
              <li>{intl.formatMessage(messages.secondViceChairFields)}</li>
            </ul>
          </Accordion.Content>
        </Accordion>
      </Form>
    </Segment>
  );
};

CommitteeLeadershipEdit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CommitteeLeadershipEdit); 