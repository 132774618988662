import React from 'react';
import { Item } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { SidebarPortal } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import CommitteeLeadershipEdit from './CommitteeLeadershipEdit';
import './committee-leadership.css';
import TxBizStar from '../Committees/txbizlawstar.svg';

const LeadershipItem = ({ photo, role, name, title, firm, titleOverride }) => {
  // Determine if we have a valid image URL or need to use the default
  const hasPhoto = photo && photo !== '';
  const imageUrl = hasPhoto ? photo : TxBizStar;
  
  // Use the override if available, otherwise use the default role
  const displayRole = titleOverride || role;
  
  return (
    <Item>
      <Item.Image 
        size="small" 
        src={imageUrl} 
        className="leadership-photo"
      />
      <Item.Content>
        <Item.Meta>
          <span className="leadership-role">{displayRole}</span>
        </Item.Meta>
        <Item.Header as="h3" className="leadership-name">{name || 'Position Open'}</Item.Header>
        {title && <Item.Description className="leadership-title">{title}</Item.Description>}
        {firm && <Item.Description className="leadership-firm">{firm}</Item.Description>}
      </Item.Content>
    </Item>
  );
};

const CommitteeLeadershipBlock = (props) => {
  const { data, onChangeBlock, block, selected } = props;
  
  // Get the current content directly from the redux store
  const contentData = useSelector(state => state.content.data);
  
  // Function to safely get committee fields from content data
  const getCommitteeFields = (content) => {
    if (!content) return {};
    
    // Try to get fields from different possible locations
    const chairName = 
      content.committee_chair_name || 
      content.committee_chair || 
      (content.data && content.data.committee_chair_name);
      
    const chairTitle = 
      content.committee_chair_title || 
      (content.data && content.data.committee_chair_title);
      
    const chairFirm = 
      content.committee_chair_firm || 
      (content.data && content.data.committee_chair_firm);
      
    const chairPhoto = 
      content.committee_chair_photo || 
      (content.data && content.data.committee_chair_photo);
      
    const viceChairName = 
      content.committee_vice_chair_name || 
      content.committee_vice_chair || 
      (content.data && content.data.committee_vice_chair_name);
      
    const viceChairTitle = 
      content.committee_vice_chair_title || 
      (content.data && content.data.committee_vice_chair_title);
      
    const viceChairFirm = 
      content.committee_vice_chair_firm || 
      (content.data && content.data.committee_vice_chair_firm);
      
    const viceChairPhoto = 
      content.committee_vice_chair_photo || 
      (content.data && content.data.committee_vice_chair_photo);
      
    const secondViceChairName = 
      content.committee_second_vice_chair_name || 
      (content.data && content.data.committee_second_vice_chair_name);
      
    const secondViceChairTitle = 
      content.committee_second_vice_chair_title || 
      (content.data && content.data.committee_second_vice_chair_title);
      
    const secondViceChairFirm = 
      content.committee_second_vice_chair_firm || 
      (content.data && content.data.committee_second_vice_chair_firm);
      
    const secondViceChairPhoto = 
      content.committee_second_vice_chair_photo || 
      (content.data && content.data.committee_second_vice_chair_photo);

    // Get title overrides
    const chairTitleOverride = content.chair_title_override;
    const viceChairTitleOverride = content.vice_chair_title_override;
    const secondViceChairTitleOverride = content.second_vice_chair_title_override;
    
    return {
      chairName,
      chairTitle,
      chairFirm, 
      chairPhoto,
      viceChairName,
      viceChairTitle,
      viceChairFirm,
      viceChairPhoto,
      secondViceChairName,
      secondViceChairTitle,
      secondViceChairFirm,
      secondViceChairPhoto,
      chairTitleOverride,
      viceChairTitleOverride,
      secondViceChairTitleOverride,
      baseUrl: content['@id']
    };
  };
  
  // Extract committee fields
  const committeeFields = getCommitteeFields(contentData);
  
  // Debug logging
  console.log('CommitteeLeadershipBlock - Extracted Fields:', committeeFields);
  
  // If we're not in a committee context, show an info message
  if (!contentData || contentData['@type'] !== 'committee') {
    return (
      <>
        <div className="committee-leadership-block">
          <h2 className="committee-leadership-heading">Leadership</h2>
          <p className="no-leadership-data">This block only works when added to a committee page.</p>
        </div>
        {renderSidebar()}
      </>
    );
  }
  
  // Check if we have committee leadership data
  const hasChair = committeeFields.chairName;
  const hasViceChair = committeeFields.viceChairName;
  const hasSecondViceChair = committeeFields.secondViceChairName;
  
  // Show appropriate message if no leadership data
  if (!hasChair && !hasViceChair && !hasSecondViceChair) {
    return (
      <>
        <div className="committee-leadership-block">
          <h2 className="committee-leadership-heading">Leadership</h2>
          <p className="no-leadership-data">No committee leadership information available.</p>
        </div>
        {renderSidebar()}
      </>
    );
  }

  // Render the edit sidebar
  const renderSidebar = () => (
    <SidebarPortal selected={selected}>
      <CommitteeLeadershipEdit
        data={data}
        block={block}
        onChangeBlock={onChangeBlock}
      />
    </SidebarPortal>
  );

  // If we have leadership data, display it
  return (
    <>
      <div className="committee-leadership-block">
        <h2 className="committee-leadership-heading">Leadership</h2>
        
        <Item.Group className="leadership-items">
          {hasChair && (
            <LeadershipItem
              role="CHAIR"
              name={committeeFields.chairName}
              title={committeeFields.chairTitle}
              firm={committeeFields.chairFirm}
              photo={committeeFields.chairPhoto ? 
                `${committeeFields.baseUrl}/@@images/committee_chair_photo/preview` : null}
              titleOverride={committeeFields.chairTitleOverride}
            />
          )}
          
          {hasViceChair && (
            <LeadershipItem
              role="VICE CHAIR"
              name={committeeFields.viceChairName}
              title={committeeFields.viceChairTitle}
              firm={committeeFields.viceChairFirm}
              photo={committeeFields.viceChairPhoto ? 
                `${committeeFields.baseUrl}/@@images/committee_vice_chair_photo/preview` : null}
              titleOverride={committeeFields.viceChairTitleOverride}
            />
          )}
          
          {hasSecondViceChair && (
            <LeadershipItem
              role="SECOND VICE CHAIR"
              name={committeeFields.secondViceChairName}
              title={committeeFields.secondViceChairTitle}
              firm={committeeFields.secondViceChairFirm}
              photo={committeeFields.secondViceChairPhoto ? 
                `${committeeFields.baseUrl}/@@images/committee_second_vice_chair_photo/preview` : null}
              titleOverride={committeeFields.secondViceChairTitleOverride}
            />
          )}
        </Item.Group>
      </div>
      
      {renderSidebar()}
    </>
  );
};

CommitteeLeadershipBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default CommitteeLeadershipBlock; 