import React, { useState } from 'react';
import { Icon } from '@plone/volto/components';
import { Card, Label, Button, Accordion } from 'semantic-ui-react';
import { formatDistanceToNow } from 'date-fns';
import './bill-status.css';

const statusColors = {
  pending: 'yellow',
  enacted: 'green',
  dead: 'red',
};

const BillStatusCard = ({ bill }) => {
  const [isTimelineOpen, setIsTimelineOpen] = useState(false);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return dateString;
    }
  };

  return (
    <Card fluid className="bill-status-card">
      <Card.Content>
        <Card.Header>
          <div className="bill-header">
            <div className="bill-session">{bill.legislative_session}</div>
            <div className="bill-number">{bill.bill_number}</div>
          </div>
        </Card.Header>
        <Card.Meta>
          <Label
            color={statusColors[bill.status] || 'grey'}
            className="status-label"
          >
            {bill.status?.toUpperCase()}
          </Label>
        </Card.Meta>
        <Card.Description>
          <p className="bill-description">{bill.description}</p>
          
          <div className="sponsors-section">
            <h4>Sponsors</h4>
            <p><strong>Primary:</strong> {bill.primary_sponsor}</p>
            {bill.co_sponsors?.length > 0 && (
              <p>
                <strong>Co-Sponsors:</strong>{' '}
                {bill.co_sponsors.join(', ')}
              </p>
            )}
          </div>

          <div className="current-status">
            <h4>Current Status</h4>
            <p>{bill.current_status_details}</p>
          </div>

          <Accordion fluid>
            <Accordion.Title
              active={isTimelineOpen}
              onClick={() => setIsTimelineOpen(!isTimelineOpen)}
            >
              <Icon name={isTimelineOpen ? 'angle down' : 'angle right'} />
              Status History
            </Accordion.Title>
            <Accordion.Content active={isTimelineOpen}>
              <div className="status-timeline">
                {bill.status_history?.map((entry, index) => (
                  <div key={index} className="timeline-entry">
                    <div className="timeline-date">
                      {formatDate(entry.date)}
                    </div>
                    <div className="timeline-status">
                      <Label color={statusColors[entry.status] || 'grey'}>
                        {entry.status?.toUpperCase()}
                      </Label>
                    </div>
                    <div className="timeline-details">
                      {entry.details}
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Content>
          </Accordion>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="bill-footer">
          <div className="next-action">
            <strong>Next Action:</strong> {bill.next_action}
          </div>
          <div className="last-updated">
            Last updated {formatDate(bill.last_updated)}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default BillStatusCard; 