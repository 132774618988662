/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { CommitteeView } from './components';
import CommitteeListing from './components/Views/CommitteeListing';
import Author from './components/Views/Author';
import MindMapBlock from './components/Blocks/MindMapBlock';
import GumletVideoSVG from './components/Blocks/GumletVideo/gumlet-icon.svg';
import BillListingBlock from './components/Blocks/BillStatus/BillListingBlock';
import LegislationListingBlock from './components/Blocks/Legislation/LegislationListingBlock';
import listIcon from '@plone/volto/icons/list-bullet.svg';
import legislativeBillTrackerConfig from './components/Blocks/LegislativeBillTracker';
import MemberSearch from './components/Views/MemberSearch';
import PortalActionsControlPanel from './components/ControlPanel/PortalActionsControlPanel';
const mindMapSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M18 15a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-6-1a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-9a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM6 9a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM5 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
  </svg>
);
// import custom blocks
import VerticalTabMenuView from './components/Blocks/VerticalTabMenu/View';
import VerticalTabMenuEdit from './components/Blocks/VerticalTabMenu/Edit';
import icon from '@plone/volto/icons/list-bullet.svg';
import PeriodicPageHeaderView from './components/Blocks/PeriodicPageHeader/View';
import PeriodicPageHeaderEdit from './components/Blocks/PeriodicPageHeader/Edit';
import TXBizLawCTAView from './components/Blocks/TXBizLawCTA/View';
import TXBizLawCTAEdit from './components/Blocks/TXBizLawCTA/Edit';
import SimpleSearchEdit from './components/Blocks/SimpleSearch/Search';
import SimpleSearchView from './components/Blocks/SimpleSearch/Search';

import CommitteeListTemplate from './components/Blocks/ListingVarations/CommitteeListTemplate/CommitteeListTemplate';
import GridListTemplate from './components/Blocks/ListingVarations/GridListTemplate/GridListTemplate';
import ResourceGridTemplate from './components/Blocks/ListingVarations/ResourceGridTemplate/ResourceGridTemplate';
import GatedContentListTemplate from './components/Blocks/ListingVarations/GatedContentListTemplate/GatedContentListTemplate';
import PersonalUrlsWidget from './components/Widgets/PersonalUrlsWidget';
import CommitteesView from './components/Blocks/Committees/Committees';
import CommitteesEdit from './components/Blocks/Committees/CommitteesEdit';
import AskAnythingBlock from './components/Blocks/AskAnythingBlock/AskAnythingBlock';
import { GumletVideoView } from './components/Blocks/GumletVideo';
import { GumletVideoEdit } from './components/Blocks/GumletVideo';
import userfullname from './reducers/userfullname/userfullname';
import StatusHistoryWidget from './components/Widgets/StatusHistoryWidget';
import './components/Widgets/StatusHistoryWidget.less';
import LegislationListTemplate from './components/Blocks/ListingVarations/LegislationListTemplate/LegislationListTemplate';
import committeeLeadershipBlockConfig from './components/Blocks/CommitteeLeadership';
import LegislativeBillTrackerView from './components/Blocks/LegislativeBillTracker/LegislativeBillTrackerView';
import LegislativeBillTrackerEdit from './components/Blocks/LegislativeBillTracker/LegislativeBillTrackerEdit';
import AuthorView from './components/Views/Author';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.addonReducers = {
    ...config.addonReducers,
    userfullname,
  };

  // Register views for content types
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    'txbizlaw-author': Author,
  };

  // Register routes
  config.settings = {
    ...config.settings,
    nonContentRoutes: [
      ...(config.settings.nonContentRoutes || []),
      '/member-search',
      '/member-search/*',
    ],
    // Disable workflow checks for these paths
    workflowMapping: {
      ...(config.settings.workflowMapping || {}),
      '/member-search': false,
    },
    // Disable navigation expansion for these paths
    doNotExpandNavigation: [
      ...(config.settings.doNotExpandNavigation || []),
      '/member-search',
    ],
  };

  // Register views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'txbizlaw-author': Author,
    },
    layoutViews: {
      ...config.views.layoutViews,
      author_view: AuthorView,
    },
  };

  // Register the member search as a route
  config.addonRoutes = [
    ...(config.addonRoutes || []),
    {
      path: '/member-search',
      component: MemberSearch,
    },
  ];

  config.settings.navDepth = 3; // Ensure proper navigation depth
  config.settings.hasNavigationComponent = true; // Enable navigation component

  // Apply the committee leadership block configuration
  config = committeeLeadershipBlockConfig(config);
  
  // Apply the legislative bill tracker block configuration
  config = legislativeBillTrackerConfig(config);

  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    mindMap: {
      id: 'mindMap',
      title: 'Mind Map',
      icon: mindMapSVG,
      group: 'common',
      view: MindMapBlock,
      edit: MindMapBlock,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    verticalTabMenu: {
      id: 'verticalTabMenu',
      title: 'Vertical Tab Menu',
      edit: VerticalTabMenuEdit,
      view: VerticalTabMenuView,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    askanythingblock: {
      id: 'askanythingblock',
      title: 'Ask Anything Block',
      edit: AskAnythingBlock,
      view: AskAnythingBlock,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
    },
    gumletVideo: {
      id: 'gumletVideo',
      title: 'Gumlet Video',
      icon: GumletVideoSVG,
      group: 'media',
      view: GumletVideoView,
      edit: GumletVideoEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    committees: {
      id: 'committees',
      title: 'Committees',
      icon: 'group',
      group: 'common',
      edit: CommitteesEdit,
      view: CommitteesView,
      restricted: false,
      mostUsed: true,
      blockHasOwnFocusManagement: true,
    },
    periodicPageHeader: {
      id: 'periodicPageHeader',
      title: 'Periodic Page Header',
      edit: PeriodicPageHeaderEdit,
      view: PeriodicPageHeaderView,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
    },
    txBizLawCTA: {
      id: 'txBizLawCTA',
      title: 'TXBizLaw CTA',
      edit: TXBizLawCTAEdit,
      view: TXBizLawCTAView,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
    },
    simpleSearchBlock: {
      id: 'simpleSearchBlock',
      title: 'Simple Search Block',
      edit: SimpleSearchEdit,
      view: SimpleSearchView,
      icon: icon,
      group: 'text',
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
    },
    billListing: {
      id: 'billListing',
      title: 'Bill Listing',
      icon: listIcon,
      group: 'common',
      view: BillListingBlock,
      edit: BillListingBlock,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    legislationListing: {
      id: 'legislationListing',
      title: 'Legislation Listing',
      icon: listIcon,
      group: 'common',
      view: LegislationListingBlock,
      edit: LegislationListingBlock,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    legislativeBillTracker: {
      id: 'legislativeBillTracker',
      title: 'Legislative Bill Tracker',
      icon: listIcon,
      group: 'common',
      view: LegislativeBillTrackerView,
      edit: LegislativeBillTrackerEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
  };

  if (config.blocks.blocksConfig.listing) {
    config.blocks.blocksConfig.listing.variations = [
      ...(config.blocks.blocksConfig.listing.variations || []),
      {
        id: 'gridListTemplate',
        title: 'Card Grid List',
        template: GridListTemplate,
      },
      {
        id: 'committeeListTemplate',
        title: 'CommitteeList',
        template: CommitteeListTemplate,
      },
      {
        id: 'gatedContentistTemplate',
        title: 'Gated Content List',
        template: GatedContentListTemplate,
      },
      {
        id: 'resourceGridTemplate',
        title: 'Resource Grid',
        template: ResourceGridTemplate,
      },
      {
        id: 'legislationListTemplate',
        title: 'Legislation List',
        template: LegislationListTemplate,
      },
    ];
  }

  config.blocks.groupBlocksOrder = [
    ...(config.blocks.groupBlocksOrder || []),
    { id: 'mind_map', title: 'Mind Map' },
  ];

  // Custom Views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      // Comment out the custom committee view to use the default block-based layout
      // committee: CommitteeView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      committee_listing: CommitteeListing,
    },
  };

  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      personal_urls: PersonalUrlsWidget,
      'status_history': StatusHistoryWidget,
    },
  };

  return config;
}
