import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Button, Image, Message } from 'semantic-ui-react';
import { Toast } from '@plone/volto/components';
import { useIntl, defineMessages } from 'react-intl';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

const messages = defineMessages({
  title: {
    id: 'Portrait Manager',
    defaultMessage: 'Portrait Manager',
  },
  currentPortrait: {
    id: 'Current Portrait',
    defaultMessage: 'Current Portrait',
  },
  uploadNew: {
    id: 'Upload New Portrait',
    defaultMessage: 'Upload New Portrait',
  },
  removePortrait: {
    id: 'Remove Portrait',
    defaultMessage: 'Remove Portrait',
  },
  back: {
    id: 'Back to Profile',
    defaultMessage: 'Back to Profile',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  portraitUpdated: {
    id: 'Portrait updated successfully',
    defaultMessage: 'Portrait updated successfully',
  },
  portraitRemoved: {
    id: 'Portrait removed successfully',
    defaultMessage: 'Portrait removed successfully',
  },
  invalidFile: {
    id: 'Please select a valid image file',
    defaultMessage: 'Please select a valid image file',
  },
});

const PortraitManager = () => {
  const intl = useIntl();
  const history = useHistory();
  const [portraitUrl, setPortraitUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );

  useEffect(() => {
    if (userId) {
      setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);
    }
  }, [userId]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={intl.formatMessage(messages.invalidFile)}
        />,
      );
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('portrait', file);

    try {
      // First, convert the image to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1];
        
        // Send the portrait data as JSON
        const response = await fetch(`/++api++/@users/${userId}`, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            portrait: {
              data: base64data,
              encoding: 'base64',
              'content-type': file.type,
              filename: file.name,
            },
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to upload portrait');
        }

        // Force a refresh of the portrait URL to show the new image
        setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);

        toast.success(
          <Toast
            success
            title={intl.formatMessage(messages.success)}
            content={intl.formatMessage(messages.portraitUpdated)}
          />,
        );
        setLoading(false);
      };

      reader.onerror = () => {
        throw new Error('Failed to read file');
      };
    } catch (error) {
      console.error('Portrait upload error:', error);
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={error.message}
        />,
      );
      setLoading(false);
    }
  };

  const handleRemovePortrait = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/++api++/@users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          portrait: null,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove portrait');
      }

      // Force a refresh of the portrait URL
      setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);

      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.portraitRemoved)}
        />,
      );
    } catch (error) {
      console.error('Portrait removal error:', error);
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={error.message}
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="portrait-manager">
      <style>
        {`
          .portrait-manager {
            padding: 2em;
          }
          .portrait-container {
            text-align: center;
            margin: 2em 0;
            padding: 2em;
            background: #f9f9f9;
            border-radius: 4px;
          }
          .portrait-actions {
            margin-top: 2em;
            display: flex;
            gap: 1em;
            justify-content: center;
          }
          .upload-button {
            position: relative;
            overflow: hidden;
          }
          .upload-button input[type="file"] {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            cursor: pointer;
            display: block;
          }
        `}
      </style>
      <h1>{intl.formatMessage(messages.title)}</h1>
      <div className="portrait-container">
        <h2>{intl.formatMessage(messages.currentPortrait)}</h2>
        <Image src={portraitUrl} alt="User portrait" size="medium" centered />
        <div className="portrait-actions">
          <Button
            primary
            className="upload-button"
            loading={loading}
            disabled={loading}
          >
            {intl.formatMessage(messages.uploadNew)}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              disabled={loading}
            />
          </Button>
          <Button
            negative
            onClick={handleRemovePortrait}
            loading={loading}
            disabled={loading}
          >
            {intl.formatMessage(messages.removePortrait)}
          </Button>
        </div>
      </div>
      <Button onClick={() => history.push('/personal-information')}>
        {intl.formatMessage(messages.back)}
      </Button>
    </Container>
  );
};

export default PortraitManager; 