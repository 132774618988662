import React, { useState } from 'react';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import LegislativeBillTrackerView from './LegislativeBillTrackerView';
import { Message } from 'semantic-ui-react';
import './legislative-bill-tracker.css';

const messages = defineMessages({
  title: {
    id: 'legislative-bill-tracker-edit-title',
    defaultMessage: 'Legislative Bill Tracker Settings',
  },
  description: {
    id: 'legislative-bill-tracker-edit-description',
    defaultMessage: 'Configure the legislative bill tracker settings.',
  },
  csvUrlLabel: {
    id: 'legislative-bill-tracker-csv-url-label',
    defaultMessage: 'CSV Data URL',
  },
  csvUrlDescription: {
    id: 'legislative-bill-tracker-csv-url-description',
    defaultMessage: 'Enter the URL of the CSV file containing the bill data. Leave empty to use sample data.',
  },
  errorMessage: {
    id: 'legislative-bill-tracker-error',
    defaultMessage: 'An error occurred while loading the bill tracker. Please try again.',
  },
});

const LegislativeBillTrackerEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;
  const intl = useIntl();
  const [error, setError] = useState(null);

  const schema = {
    title: intl.formatMessage(messages.title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['csvUrl'],
      },
    ],
    properties: {
      csvUrl: {
        title: intl.formatMessage(messages.csvUrlLabel),
        description: intl.formatMessage(messages.csvUrlDescription),
        type: 'string',
      },
    },
    required: [],
  };

  const handleError = (err) => {
    console.error('Legislative Bill Tracker Error:', err);
    setError(intl.formatMessage(messages.errorMessage));
  };

  return (
    <div className="block legislative-bill-tracker-edit">
      {error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      )}
      
      {!data.csvUrl && (
        <div className="placeholder-message">
          <h4>Legislative Bill Tracker</h4>
          <p>Add a CSV URL to display live bill data, or leave empty to use sample data.</p>
        </div>
      )}
      
      <div className="editor-wrapper">
        <LegislativeBillTrackerView 
          {...props} 
          mode="edit" 
          onError={handleError}
        />
      </div>

      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            setError(null);
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </div>
  );
};

export default LegislativeBillTrackerEdit; 