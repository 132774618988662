import CommitteeLeadershipBlock from './CommitteeLeadershipBlock';
import CommitteeLeadershipEdit from './CommitteeLeadershipEdit';

const customizeBlocks = (config) => {
  config.blocks.blocksConfig.committeeLeadership = {
    id: 'committeeLeadership',
    title: 'Committee Leadership',
    icon: 'users',
    group: 'common',
    view: CommitteeLeadershipBlock,
    edit: CommitteeLeadershipEdit,
    restricted: false,
    mostUsed: true,
    blockHasOwnFocusManagement: true,
  };

  return config;
};

export default customizeBlocks; 