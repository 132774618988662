import React, { useState, useEffect } from 'react';
import { Container, Grid, Input, Card, Icon, Message, Dropdown, Image } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { useLocation } from 'react-router-dom';
import TxBizStar from '../Blocks/Committees/txbizlawstar.svg';

const MemberSearch = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [practiceArea, setPracticeArea] = useState('');
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [failedImages, setFailedImages] = useState(new Set());

  // Fetch practice areas on component mount
  useEffect(() => {
    const fetchPracticeAreas = async () => {
      try {
        const response = await fetch('/++api++/@vocabularies/txbizlaw.plone.legal_practice_areas', {
          headers: {
            Accept: 'application/json',
          },
          method: 'GET',
          credentials: 'same-origin',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch practice areas');
        }

        const data = await response.json();
        const options = data.items.map(item => ({
          key: item.token,
          text: item.title,
          value: item.token,
        }));
        setPracticeAreas(options);
      } catch (error) {
        console.error('Error fetching practice areas:', error);
      }
    };

    fetchPracticeAreas();
  }, []);

  const handleSearch = async () => {
    if (!searchTerm.trim() && !practiceArea) {
      setMembers([]);
      setTotal(0);
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      const queryParams = new URLSearchParams();
      if (searchTerm.trim()) {
        queryParams.append('query', searchTerm.trim());
      }
      if (practiceArea) {
        queryParams.append('practice_area', practiceArea);
      }
      queryParams.append('limit', '25');

      const response = await fetch(
        `/++api++/@member-directory-search?${queryParams.toString()}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
          credentials: 'same-origin',
        }
      );

      if (!response.ok) {
        throw new Error(`Search failed: ${response.statusText}`);
      }

      const data = await response.json();
      setMembers(data.items || []);
      setTotal(data.items_total || 0);

      if (data.items_total === 0) {
        setError(`No members found matching your search criteria`);
      }
    } catch (error) {
      console.error('Search error:', error);
      setError(error.message || 'Failed to search members. Please try again.');
      setMembers([]);
      setTotal(0);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm.trim() || practiceArea) {
        handleSearch();
      } else {
        setMembers([]);
        setTotal(0);
        setError(null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm, practiceArea]);

  // Clear search when navigating away
  useEffect(() => {
    return () => {
      setSearchTerm('');
      setPracticeArea('');
      setMembers([]);
      setTotal(0);
      setError(null);
    };
  }, [location.pathname]);

  return (
    <div className="ui wrapper member-search">
      <Container>
        <div className="container">
          <h1>Member Directory</h1>
          
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="search-controls">
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Input
                          icon="search"
                          placeholder="Search members by name..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          fluid
                          size="large"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Dropdown
                          placeholder="Filter by practice area..."
                          fluid
                          selection
                          clearable
                          options={practiceAreas}
                          value={practiceArea}
                          onChange={(e, { value }) => setPracticeArea(value)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>

                {error && (
                  <Message 
                    warning={error.includes('No members found')}
                    negative={!error.includes('No members found')}
                  >
                    <Icon name={error.includes('No members found') ? 'search' : 'warning'} />
                    {error}
                  </Message>
                )}

                {total > 0 && !error && (
                  <Message info>
                    <Icon name="info circle" />
                    Found {total} member{total !== 1 ? 's' : ''}
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {loading ? (
                  <div className="loading">
                    <Icon name="spinner" loading />
                    Searching...
                  </div>
                ) : members.length > 0 ? (
                  <Card.Group>
                    {members.map((member) => (
                      <UniversalLink
                        key={member.id}
                        href={`/author/${member.id}`}
                        className="member-card-link"
                      >
                        <Card className="member-card">
                          <Card.Content>
                            <div className="member-header">
                              <Image
                                className="member-portrait"
                                src={member.has_portrait ? `/++api++/@portrait/${member.id}` : TxBizStar}
                                alt={member.fullname}
                                size="tiny"
                                onError={(e) => {
                                  if (!e.target.src.includes(TxBizStar)) {
                                    e.target.src = TxBizStar;
                                  }
                                }}
                              />
                              <div className="member-info">
                                <Card.Header>
                                  {member.fullname || member.username}
                                </Card.Header>
                                {member.email && !member.email_private && (
                                  <Card.Meta>
                                    <Icon name="mail" />
                                    {member.email}
                                  </Card.Meta>
                                )}
                                {member.description && (
                                  <Card.Description>
                                    {member.description}
                                  </Card.Description>
                                )}
                                {member.practice_areas && member.practice_areas.length > 0 && (
                                  <Card.Meta>
                                    <Icon name="briefcase" />
                                    {member.practice_areas.join(', ')}
                                  </Card.Meta>
                                )}
                                {member.location && (
                                  <Card.Meta>
                                    <Icon name="map marker alternate" />
                                    {member.location}
                                  </Card.Meta>
                                )}
                              </div>
                            </div>
                          </Card.Content>
                        </Card>
                      </UniversalLink>
                    ))}
                  </Card.Group>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default MemberSearch; 