/**
 * CommitteeChair component.
 * @module components/CommitteeChair
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import txBizLawStar from './txbizlawstar.svg';
import './CommitteeChair.css';

/**
 * CommitteeChair component.
 * @function CommitteeChair
 * @param {Object} props Component properties.
 * @returns {string} Markup of the component.
 */
const CommitteeChair = ({
  chairPhoto,
  chairPosition,
  chairTitle,
  chairName,
  chair,
  chairFirm,
  chairLocation,
  contactButton,
  chairTitleOverride,
  viceChairTitleOverride,
  secondViceChairTitleOverride,
}) => {
  // Make sure we have a name, fall back to default
  const displayName = chairName || chair || 'Committee Chair';

  // Position mapping for more human-readable labels, with override support
  const getPositionTitle = (position) => {
    switch (position) {
      case 'chair':
        return chairTitleOverride || 'CHAIR';
      case 'co-chair':
      case 'vice chair':
        return viceChairTitleOverride || 'VICE CHAIR';
      case 'second-vice-chair':
      case 'second vice chair':
        return secondViceChairTitleOverride || 'SECOND VICE CHAIR';
      default:
        return position.toUpperCase();
    }
  };

  const titleText = getPositionTitle(chairPosition);

  return (
    <div className="committee-chair-info">
      <div className="committee-chair-image-container">
        <img
          src={chairPhoto || txBizLawStar}
          alt={displayName}
          className="committee-chair-image"
        />
      </div>
      <div>
        <div className="committee-chair-position">
          {titleText}
        </div>
        <div className="committee-chair-name">
          {displayName}
        </div>
        {chairTitle && (
          <div className="committee-chair-title">
            {chairTitle}
          </div>
        )}
        {chairFirm && (
          <div className="committee-chair-firm">
            {chairFirm}
          </div>
        )}
        {chairLocation && (
          <div className="committee-chair-location">
            {chairLocation}
          </div>
        )}
        {contactButton && (
          <div className="committee-chair-contact">
            <UniversalLink href={contactButton}>
              <Button size="mini">
                <FormattedMessage id="Download vCard" defaultMessage="Download vCard" />
              </Button>
            </UniversalLink>
          </div>
        )}
      </div>
    </div>
  );
};

CommitteeChair.propTypes = {
  chairPhoto: PropTypes.string,
  chairPosition: PropTypes.string,
  chairTitle: PropTypes.string,
  chairName: PropTypes.string,
  chair: PropTypes.string,
  chairFirm: PropTypes.string,
  chairLocation: PropTypes.string,
  contactButton: PropTypes.string,
  chairTitleOverride: PropTypes.string,
  viceChairTitleOverride: PropTypes.string,
  secondViceChairTitleOverride: PropTypes.string,
};

export default CommitteeChair;
