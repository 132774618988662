import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Dropdown, Input, Grid, Segment } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import BillStatusCard from './BillStatusCard';
import config from '@plone/volto/registry';

const messages = defineMessages({
  title: {
    id: 'Bill Listing',
    defaultMessage: 'Bill Listing',
  },
  searchPlaceholder: {
    id: 'Search bills...',
    defaultMessage: 'Search bills...',
  },
  statusFilter: {
    id: 'Filter by status',
    defaultMessage: 'Filter by status',
  },
  sessionFilter: {
    id: 'Filter by session',
    defaultMessage: 'Filter by session',
  },
});

const statusOptions = [
  { key: 'all', text: 'All Statuses', value: 'all' },
  { key: 'pending', text: 'Pending', value: 'pending' },
  { key: 'enacted', text: 'Enacted', value: 'enacted' },
  { key: 'dead', text: 'Dead', value: 'dead' },
];

const BillListingBlock = ({ data, block }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedSession, setSelectedSession] = useState('all');

  // Get unique sessions from the data
  const sessionOptions = [
    { key: 'all', text: 'All Sessions', value: 'all' },
    ...Array.from(
      new Set(data.items?.map((item) => item.legislative_session) || []),
    ).map((session) => ({
      key: session,
      text: session,
      value: session,
    })),
  ];

  // Filter bills based on search and filters
  const filteredBills = data.items?.filter((bill) => {
    const matchesSearch =
      searchText === '' ||
      bill.title.toLowerCase().includes(searchText.toLowerCase()) ||
      bill.bill_number.toLowerCase().includes(searchText.toLowerCase()) ||
      bill.description.toLowerCase().includes(searchText.toLowerCase());

    const matchesStatus =
      selectedStatus === 'all' || bill.status === selectedStatus;

    const matchesSession =
      selectedSession === 'all' || bill.legislative_session === selectedSession;

    return matchesSearch && matchesStatus && matchesSession;
  });

  return (
    <div className="bill-listing-block">
      <Segment>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Input
                fluid
                icon="search"
                placeholder={intl.formatMessage(messages.searchPlaceholder)}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                fluid
                selection
                options={statusOptions}
                value={selectedStatus}
                onChange={(e, { value }) => setSelectedStatus(value)}
                placeholder={intl.formatMessage(messages.statusFilter)}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                fluid
                selection
                options={sessionOptions}
                value={selectedSession}
                onChange={(e, { value }) => setSelectedSession(value)}
                placeholder={intl.formatMessage(messages.sessionFilter)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <div className="bills-container">
        {filteredBills?.map((bill) => (
          <BillStatusCard key={bill['@id']} bill={bill} />
        ))}
        {filteredBills?.length === 0 && (
          <p className="no-results">No bills found matching your criteria.</p>
        )}
      </div>
    </div>
  );
};

export default BillListingBlock; 