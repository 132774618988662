import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Table, Icon } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  statusHistory: {
    id: 'Status History',
    defaultMessage: 'Status History',
  },
});

const StatusHistoryWidget = (props) => {
  const { id, value = [], onChange } = props;
  const intl = useIntl();

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(value);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(id, items);
  };

  return (
    <FormFieldWrapper {...props} className="status-history-widget">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="status-history">
          {(provided) => (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Details</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body {...provided.droppableProps} ref={provided.innerRef}>
                {value.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Table.Row
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? 'dragging' : ''}
                      >
                        <Table.Cell>
                          <Icon name="bars" />
                        </Table.Cell>
                        <Table.Cell>{item.date}</Table.Cell>
                        <Table.Cell>{item.status}</Table.Cell>
                        <Table.Cell>{item.details}</Table.Cell>
                      </Table.Row>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Table.Body>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </FormFieldWrapper>
  );
};

export default StatusHistoryWidget; 