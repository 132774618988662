import React, { useState } from 'react';
import { Icon } from '@plone/volto/components';
import { Card, Label, Button, Accordion } from 'semantic-ui-react';
import { formatDistanceToNow } from 'date-fns';
import './legislation.css';

const statusColors = {
  pending: 'yellow',
  enacted: 'green',
  dead: 'red',
};

const LegislationCard = ({ legislation }) => {
  const [isTimelineOpen, setIsTimelineOpen] = useState(false);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return dateString;
    }
  };

  return (
    <Card fluid className="legislation-card">
      <Card.Content>
        <Card.Header>
          <div className="legislation-header">
            <div className="legislation-session">{legislation.legislative_session}</div>
            <div className="legislation-number">{legislation.bill_number}</div>
          </div>
        </Card.Header>
        <Card.Meta>
          <Label
            color={statusColors[legislation.status] || 'grey'}
            className="status-label"
          >
            {legislation.status?.toUpperCase()}
          </Label>
        </Card.Meta>
        <Card.Description>
          <p className="legislation-description">{legislation.description}</p>
          
          <div className="sponsors-section">
            <h4>Sponsors</h4>
            <p><strong>Primary:</strong> {legislation.primary_sponsor}</p>
            {legislation.co_sponsors?.length > 0 && (
              <p>
                <strong>Co-Sponsors:</strong>{' '}
                {legislation.co_sponsors.join(', ')}
              </p>
            )}
          </div>

          <div className="current-status">
            <h4>Current Status</h4>
            <p>{legislation.current_status_details}</p>
          </div>

          <Accordion fluid>
            <Accordion.Title
              active={isTimelineOpen}
              onClick={() => setIsTimelineOpen(!isTimelineOpen)}
            >
              <Icon name={isTimelineOpen ? 'angle down' : 'angle right'} />
              Status History
            </Accordion.Title>
            <Accordion.Content active={isTimelineOpen}>
              <div className="status-timeline">
                {legislation.status_history?.map((entry, index) => (
                  <div key={index} className="timeline-entry">
                    <div className="timeline-date">
                      {formatDate(entry.date)}
                    </div>
                    <div className="timeline-status">
                      <Label color={statusColors[entry.status] || 'grey'}>
                        {entry.status?.toUpperCase()}
                      </Label>
                    </div>
                    <div className="timeline-details">
                      {entry.details}
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Content>
          </Accordion>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="legislation-footer">
          <div className="next-action">
            <strong>Next Action:</strong> {legislation.next_action}
          </div>
          <div className="last-updated">
            Last updated {formatDate(legislation.last_updated)}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default LegislationCard; 